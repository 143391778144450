/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Custom Imports */
@import "theme/fonts";
@import "theme/medias";
@import "theme/mixins";
@import "theme/custom-variables";

//--Body---------------------------------//
body {
  --ion-background-color: var(--bg-body);
  --ion-font-family: "#{$font}";
  color: $font-color;
  font-family: $font;
}

//--Router Outlet------------------------//

ion-router-outlet {
  pointer-events: all !important;
}

//--TAG <img> cover----------------------//

.img-cover {
  @include prefix(transform, translate(-50%, -50%), webkit moz ms o);
  position: absolute;
  display: block;
  min-height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  object-fit: cover;
}

//--Container----------------------------//

.container {
  max-width: 100%;
  margin-bottom: 140px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media (min-width: 1600px) {
  body {
    --ion-grid-width-xl: 1600px;
    --ion-grid-width: 1600px;
  }
}

//--Toolbar-------------------------------//

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

//--Display Flex--------------------------//

.align-items-center {
  align-items: center !important;
}
.d-flex {
  display: flex !important;
}

//--Button Default------------------------//

ion-button {
  text-transform: none;
}

.btn-default {
  --background: #{$color-1};
  border: 0;
  color: #fff;
  height: auto;

  &.outline {
    --background: transparent;
    color: $font-color;

    &::part(native) {
      background-color: transparent;
      border: 1px solid $color-1;
    }

    ion-icon {
      color: $color-1;
    }
  }

  &::part(native) {
    border-radius: 8px;
    padding: 15px 20px;
  }
}

//--Button Default Request------------------------//

.btn-default-request {
  --background: #{$color-1};
  border: 0;
  color: #fff;
  height: 3.7em;

  &.outline {
    --background: transparent;
    color: $font-color;

    &::part(native) {
      background-color: transparent;
      border: 1px solid $color-1;
    }

    ion-icon {
      color: $color-1;
    }
  }

  &::part(native) {
    border-radius: 8px;
    padding: 15px 20px;
  }
}

//--Custom Radio Button------------------//

.radio-custom-box {
  .item {
    display: flex;

    label {
      color: rgba($font-color, 0.7) !important;
      display: inline-block;
      line-height: 19px;
      margin: 7px 0;
    }

    .check-radio {
      @include transition(all, 0.2, ease);

      &:not(:checked),
      &:checked {
        position: absolute;
        left: -9999px;

        &+label {
          @include transition(all, 0.2, ease);
          color: $font-color-2;
          cursor: pointer;
          font-size: 14px;
          line-height: 1;
          padding: 2px 2px 2px 30px;
          position: relative;
          width: 100%;

          &:before,
          &:after {
            @include transition(all, 0.2, ease);
            position: absolute;
            content: '';
            border-radius: 50%;
            height: 20px;
            width: 20px;
          }

          &:before {
            border: 1px solid $color-2;
            left: 2px;
            top: -2px;
          }

          &:after {
            @include transition(all, .3, ease);
            background-color: $color-2;
            top: 3px;
            left: 7px;
            height: 12px;
            opacity: 0;
            width: 12px;
          }
        }
      }

      &:checked {
        &+label {
          color: $color-1 !important;

          &:after {
            opacity: 1;
          }
        }
      }

      &:hover {
        &+label {
          color: $color-1 !important;
        }
      }
    }
  }
}

//--Radio--------------------------------//

ion-radio-group {
  ion-item {
    ion-label {
      --color: #{$font-color-2} !important;
    }

    ion-radio {
      --color-checked: #{$color-1};
    }
  }
}

ion-radio {
  --color: #{$color-1};
  --color-checked: #{$color-1};
}

//--Checkbox-----------------------------//

ion-checkbox {
  --background-checked: #{$color-1};
  --border-color: #{$font-color-2};
  --border-color-checked: #{$color-1};
}

//--Chip---------------------------------//

ion-chip {
  --background: #{rgba($color-1, .1)};
  border: 1px solid $color-1;
  border-radius: 4px;
  --color: #{$color-1};
  font-weight: normal;
  margin: 0;

  &.red {
    --background: #{rgba(#FD0000, .1)};
    border-color: #FD0000;
    --color: #FD0000;
  }

  &.blue {
    --background: #{rgba(#027CD4, .1)};
    border-color: #027CD4;
    --color: #027CD4;
  }

  &.green {
    --background: #{rgba(#18C71F, .1)};
    border-color: #18C71F;
    --color: #18C71F;
  }
}

//--Fab Button---------------------------//

ion-fab-button {
  --background: #{$color-1};
}

//--Segment------------------------------//

ion-segment {
  background-color: transparent;
  margin-bottom: 15px;

  ion-segment-button {
    color: $font-color-2;
    font-weight: normal;
    margin: 0;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      background-color: $color-1;
      bottom: 0;
      left: 0;
      height: 0;
      transition: all .3s ease;
      width: 100%;
    }

    &.segment-button-checked {
      color: $color-1;

      &:after {
        height: 2px;
      }
    }

    &:before {
      display: none;
    }

    &::part(native) {
      padding: 0;
    }

    &::part(indicator) {
      display: none;
    }
  }
}

//--Toggle-------------------------------//

ion-toggle {
  --background-checked: #1e8a3b;
  --handle-background-checked: #fff;

  &.md {
    --background-checked: #5ac276;
    --handle-background-checked: #1e8a3b;
  }
}

//--Modal--------------------------------//

.modal-default {
  &::part(content) {
    border-radius: 16px;
    // bottom: 50%;
    height: auto;
    max-height: 100%;
    padding-bottom: 20px;
    position: absolute;
    display: block;
    max-width: calc(100% - 30px);
  }

  ion-header {
    &:after {
      display: none;
    }

    ion-toolbar {
      --background: #fff;
      --color: $font-color;

      ion-title {
        text-align: center;
      }
    }
  }

  ion-content {
    min-height: 200px;
    ion-input, ion-select {
      border-color: #f6f8fb;
      border-radius: 8px;
    }
    ion-datetime-button {
      &::part(native) {
        background-color: transparent;
        text-align: left;
        width: 100%;
      }
    }
  }

  .modal-content {
    color: $font-color-2;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;

    p {
      margin: 0;
    }
  }
}

//--Swiper-------------------------------//

.swiper {
  &-pagination {
    &-bullet {
      height: 18px;
      opacity: 1;
      width: 18px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

//--Steps--------------------------------//

.steps {
  display: flex;
  list-style: none;
  padding-left: 0;

  .step {
    align-items: center;
    border: 1px solid $color-6;
    border-radius: 8px;
    color: $font-color-2;
    display: flex;
    height: 30px;
    justify-content: center;
    width: 30px;

    &.active {
      background-color: $color-2;
      border-color: $color-2;
      color: #fff;
    }

    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

//--Header-------------------------------//

.header {
  ion-back-button {
    color: $color-1;
    margin-right: 8px;
  }
}

//--Form Layout--------------------------//

.form-layout {
  color: $font-color;
  margin-bottom: -20px;

  .form-group {
    background-color: $color-6;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px 15px;
    position: relative;

    label,
    ion-label {
      color: $font-color-2;
      font-size: 12px;
    }

    input {
      @include transition(height, .3, ease);
      background-color: transparent;
      border: none;
      color: $font-color;
      height: 20px;
      outline: none;
      padding: 0;
      width: 100%;

      &[type="date"] {
        cursor: pointer;
      }

      &[disabled] {
        cursor: not-allowed;
      }

      &[type="password"] {
        padding-right: 35px;
      }
    }

    textarea {
      @extend input;
      height: 180px;
      max-height: 300px;
      max-width: 100%;
      min-height: 100px;
      min-width: 100%;
      width: 100%;
    }

    select {
      background-color: transparent;
      border: none;
      width: 100%;
    }

    ion-select {
      padding: 0 5px 0 0;
    }

    small {
      font-size: 14px;
    }

    button {
      @include transition(all, 0.2, ease);
      background-color: $color-1;
      border: 0;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      min-width: 125px;
      padding: 17px 15px;

      &:hover {
        background: $color-2;
        color: #fff;
      }
    }

    .attachment {
      align-items: center;
      display: flex;
      margin-bottom: 0;
      width: 100%;

      input {
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        height: 0;
        opacity: 0;
        padding: 0;
        width: 0;
      }

      .text {
        color: $font-color;
        display: block;
        flex-grow: 1;
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        margin-right: 15px;
        overflow: hidden;
        position: relative;
        word-wrap: anywhere;
      }

      ion-icon {
        color: $font-color-2;
        font-size: 24px;
      }
    }

    .password {
      position: relative;

      .switchVisibility {
        position: absolute;
        right: 10px;
        top: 32px;
        height: auto;
        opacity: 0;
        width: auto;

        +label {
          position: absolute;
          right: 5px;
          top: 50%;
          color: rgb(155, 155, 155);
          font-size: 22px;
          transform: translateY(-10px);

          .icon-text {
            display: none;
          }
        }

        &:focus {
          +label {
            outline: 1px solid #222;
          }
        }

        &:checked {
          +label {
            .icon-text {
              display: block;
            }

            .icon-pass {
              display: none;
            }
          }
        }
      }
    }
  }
}

//--Notifies-----------------------------//

.notify {
  align-items: center;
  display: flex;
  text-align: left;
  white-space: break-spaces;

  &-icon {
    align-items: center;
    background-color: #FFF5D2;
    border-radius: 4px;
    color: #FF7F35;
    display: flex;
    font-size: 24px;
    height: 48px;
    justify-content: center;
    margin-right: 15px;
    min-width: 48px;
    width: 48px;
  }

  &-info {
    &--date {
      color: #929BA9;
      font-size: 12px;
      font-weight: normal;
    }

    &--title {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      margin: 0;
    }
  }

  &.new {
    .notify {
      &-icon {
        background-color: $color-1;
        color: #fff;
      }

      &-info {
        &--title {
          font-weight: bold;
        }
      }
    }
  }
}

//--Header-------------------------------//

.header-popover {
  ion-list {
    ion-item {
      &::part(native) {
        padding: 0 10px;
      }
      ion-button {
        --background-hover: transparent;
        --border-radius: 4px;
        border-bottom: 1px solid #F1F0F2;
        --color-hover: #{$color-1};
        font-size: 14px;
        height: auto;
        margin: 0;
        padding-bottom: 8px;
        width: calc(100% + 14px);
        .content {
          align-items: center;
          display: flex;
          width: 100%;
          img {
            display: block;
            margin-right: 15px;
            min-width: 32px;
            width: 32px;
          }
        }
      }
      &:last-child {
        ion-button {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
  .see-all {
    --background-hover: transparent;
    --color-hover: #{$color-1};
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}

.header-popover[trigger="notificacoes-button"] {
  &::part(content) {
    width: 350px;
  }

  ion-list {
    ion-item {
      &:last-child {
        ion-button {
          border-bottom: 1px solid #F1F0F2;
          padding-bottom: 8px;
        }
      }
    }
  }
}

.header-popover[trigger="perfil-button"] {
  &::part(content) {
    width: 300px;
  }
}

//--Grains-------------------------------//

.grains {
  .grain {
    &:not(:first-child) {
      margin-top: 25px;
    }

    ion-button {
      height: auto;
      margin: 0 -15px;

      &::part(native) {
        border-radius: 0;
        padding: 0;
      }
    }

    &-title {
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(#000, .2);
      display: flex;
      justify-content: space-between;
      margin: 30px 0 15px;
      padding: 15px;

      &--name {
        color: $font-color;
        font-size: 16px;
        font-weight: 500;
        margin: 0 15px 0 0;
      }

      &--qtt {
        align-items: center;
        color: $color-1;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        margin: 0;

        ion-icon {
          font-size: 24px;
        }
      }
    }

    &-info {
      background-color: $color-6;
      border-radius: 4px;
      padding: 15px;
      text-align: left;
      width: 100%;

      &--name {
        color: $font-color-2;
        font-size: 14px;
        font-weight: normal;
        margin: 0;
      }

      &--value {
        color: #34475A;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}

//--Weather------------------------------//

.weather {
  align-items: center;
  display: flex;

  &-icon {
    align-items: center;
    background-color: #F6F8FB;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: 45px;
    margin-right: 10px;
    padding: 5px;
    min-width: 45px;

    img {
      display: block;
      max-height: 100%;
    }
  }

  &-info {
    color: $font-color;
    font-size: 48px;
    font-weight: bold;

    sup {
      font-size: 16px;
      left: -10px;
      top: -20px;
    }
  }
}

//--Min Max------------------------------//

.minmax {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 100%;

  >* {
    align-items: center;
    display: flex;

    &:first-child {
      margin-right: 10px;
    }
  }

  ion-icon {
    font-size: 20px;
    margin-left: 5px;
  }

  .max {
    ion-icon {
      color: #FD0000;
    }
  }

  .min {
    ion-icon {
      color: #027CD4;
    }
  }
}

//--Header-------------------------------//

ion-header {
  background-color: $color-1;

  ion-toolbar {
    --background: transparent;
    --color: #fff;

    ion-buttons {
      ion-button {
        --color: #fff;
      }

      ion-back-button {
        --color: #fff;
        font-size: 14px;
        margin-left: 8px;
      }
    }

    ion-title {
      padding: 0 40px;
      text-align: left;
    }
  }
}

//--Dropdown Activities------------------//

.dropdown-activities {
  ion-list {
    ion-item {
      padding: 0 10px;

      &::part(native) {
        border-radius: 0;
        padding: 0;
      }

      ion-button {
        --background-hover: transparent;
        border-bottom: 1px solid #F1F0F2;
        --color: #34475A;
        --color-hover: #{$color-1};
        height: auto;
        margin: 0;
        width: 100%;

        &::part(native) {
          border-radius: 0;
          padding: 0;
        }
      }

      .activity {
        align-items: center;
        display: flex;
        width: 100%;

        &-icon {
          margin-right: 15px;
          width: 32px;
        }

        &-title {
          font-size: 16px;
          margin: 0;
        }
      }

      &:last-child {
        ion-button {
          border-bottom: none;
        }
      }
    }
  }
}

//--Last News----------------------------//

.last-news {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      margin: 0;
    }

    ion-button {
      --background-hover: transparent;
      --color: #{$color-1};
      --color-hover: #{$color-1};
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ion-list {
    ion-item {
      &::part(native) {
        padding: 0;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      ion-button {
        --background-hover: transparent;
        border-radius: 8px;
        border: 1px solid #F1F0F2;
        height: auto;
        margin: 0;
        text-align: left;
        white-space: break-spaces;

        &::part(native) {
          border-radius: 0;
          padding: 0;
        }
      }
    }
  }

  .news {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px;

    &-info {
      &--date {
        color: $color-3;
        font-size: 14px;
      }

      &--title {
        margin: 0 0 10px;
      }

      &--desc {
        color: #637281;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &-img {
      border-radius: 8px;
      height: 80px;
      margin-left: 15px;
      min-width: 80px;
      overflow: hidden;
      position: relative;
      width: 80px;
    }

    &:hover {
      .news {
        &--title {
          color: $color-1;
        }
      }
    }
  }
}

@include media-only(xs) {
  .last-news {
    .news {
      flex-direction: column-reverse;
      &-img {
        height: 150px;
        margin-bottom: 15px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

//--Last Programs------------------------//

.last-programs {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;

  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      margin: 0;
    }

    ion-button {
      --background-hover: transparent;
      --color: #{$color-1};
      --color-hover: #{$color-1};
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ion-list {
    ion-item {
      &::part(native) {
        padding: 0;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      ion-button {
        --background-hover: transparent;
        border-radius: 8px;
        border: 1px solid #F1F0F2;
        height: auto;
        margin: 0;
        text-align: left;
        white-space: break-spaces;
        width: 100%;

        &::part(native) {
          border-radius: 0;
          padding: 0;
        }
      }
    }
  }

  .program {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    width: 100%;

    &-info {
      &--date {
        color: $color-3;
        font-size: 14px;

        span {
          color: #929BA9;
        }
      }

      &--title {
        font-size: 16px;
        margin: 10px 0 0;
      }
    }

    &-icon {
      color: $color-1;
      font-size: 48px;
      margin-left: 15px;

      ion-icon {
        display: block;
      }
    }
  }
}

//--Inicial------------------------------//

.inicial {
  --background: #{$bg-body};
  --ion-grid-column-padding: 15px;

  ion-grid {
    padding: 0;

    ion-row {
      margin: 0 -15px;
    }
  }

  .swiper {
    &-inicial-container {
      position: relative;
    }

    &-pagination {
      --bullet-background-active: #{$color-2};
      bottom: 10px;
      right: 175px;

      &-bullet {
        &-active {
          background: $color-2;
        }
      }
    }
  }

  .title {
    font-size: 16px;
  }

  .activity {
    align-items: center;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    padding: 25px;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin: 0;
    }

    ion-button {
      --background: transparent;
      border-radius: 8px;
      border: 1px solid #F1F0F2;
      --box-shadow: none;
      --color: #637281;
      height: auto;
      margin: 0;
      max-width: 100%;
      width: 350px;

      &::part(native) {
        padding: 15px;
      }
    }

    &-button {
      align-items: center;
      display: flex;
      width: 100%;

      &--icon {
        margin-right: 15px;
        min-width: 52px;
        width: 52px;
      }

      &--text {
        font-size: 16px;
        font-weight: normal;
        margin: 0;
      }

      &--select {
        align-items: center;
        color: $color-1;
        display: flex;
        margin-left: auto;
      }
    }
  }

  .actions {
    background-color: #fff;
    border-radius: 16px;
    margin: 25px 0;
    padding: 15px;

    .title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      h2 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
      }

      ion-button {
        --background: transparent;
        --background-hover: transparent;
        border: 1px solid #F1F0F2;
        border-radius: 8px;
        --box-shadow: none;
        --color: #{$font-color};
        height: auto;
        margin: 0;
        max-width: 100%;
        text-align: left;
        width: 325px;

        &::part(native) {
          padding: 0;
        }

        ion-icon {
          &.active {
            display: none;
          }
        }
      }

      .activity-button {
        align-items: center;
        display: flex;
        padding: 15px;
        width: 100%;

        &--icon {
          margin-right: 15px;
          min-width: 52px;
          width: 52px;
        }

        &--info {
          &---span {
            color: #637281;
            font-size: 14px;
          }

          &---text {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
          }
        }

        &--select {
          align-items: center;
          color: $color-1;
          display: flex;
          margin-left: auto;
        }
      }
    }

    ion-slides {
      ion-slide {
        height: auto;

        ion-button {
          --background-hover: transparent;
          --box-shadow: none;
          height: 100%;
          margin: 0;
          width: 100%;

          &::part(native) {
            border-radius: 0;
            padding: 0;
          }
        }
      }
    }

    .action {
      align-items: flex-start;
      background-color: #fff;
      border: 1px solid #F1F0F2;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 20px);
      margin: 4px;
      padding: 15px;
      width: 100%;

      &-img {
        height: 32px;
        margin-bottom: 15px;

        img {
          display: block;
        }
      }

      &-text {
        color: $font-color-2;
        font-size: 14px;
        line-height: 1.5;
        text-align: left;
        white-space: normal;
      }
    }
  }

  .balance {
    background-color: #fff;
    border-radius: 16px;
    margin: 25px 0;
    padding: 15px;

    ion-button {
      height: auto;
    }

    .title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      h2 {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
      }

      ion-button {
        color: $color-1;

        &::part(native) {
          padding: 0 5px;
        }

        ion-icon {
          &.active {
            display: none;
          }
        }
      }
    }

    ion-grid {
      ion-button {
        --background-hover: transparent;
        border: 1px solid #F1F0F2;
        border-radius: 8px;
        margin: 0;

        &::part(native) {
          border-radius: 0;
          padding: 0;
        }
      }
    }

    .values {
      align-items: center;
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 15px;
      width: 100%;

      &-info {
        &--title {
          font-size: 16px;
          font-weight: normal;
          margin: 0 0 10px;
        }

        &--number {
          font-size: 20px;
          font-weight: bold;
        }
      }

      ion-icon {
        color: $color-1;
        font-size: 24px;
      }
    }

    &.hide-text {
      .title {
        ion-button {
          ion-icon {
            display: none;

            &.active {
              display: block;
            }
          }
        }
      }

      .values {
        &-info {
          &--number {
            -webkit-text-security: disc;
          }
        }
      }
    }
  }

  .prices {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;

    .title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      h2 {
        font-size: 16px;
        margin: 0;
      }

      ion-button {
        color: $color-1;
        height: auto;

        &::part(native) {
          padding: 0 5px;
        }
      }
    }

    .updated {
      color: #929BA9;
      font-size: 14px;
    }

    ion-card {
      border: 1px solid #F1F0F2;
      border-radius: 16px;
      box-shadow: none;
      margin: 0;

      ion-card-header {
        padding-bottom: 5px;

        ion-card-title {
          color: $font-color-2;
          font-size: 14px;
          font-weight: normal;
        }
      }

      ion-card-content {
        overflow: hidden;

        .price {
          align-items: center;
          color: $font-color;
          display: flex;
          font-size: 20px;
          font-weight: bold;
          justify-content: space-between;

          small {
            font-size: 16px;
            font-weight: normal;
          }

          .up {
            color: #18C71F;
          }

          .down {
            color: #FD0000;
          }
          .eq {
            color: #a1a1a1;
          }
        }

        .chart {
          margin: 5px -20px 0;

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }

    ion-list {
      ion-item {
        margin-bottom: 15px;

        &::part(native) {
          padding: 0;
        }

        ion-button {
          --background-hover: transparent;
          border: 1px solid #F1F0F2;
          border-radius: 8px;
          height: auto;
          margin: 0;
          text-align: left;
          width: 100%;

          &::part(native) {
            padding: 0;
          }
        }
      }
    }

    .movement {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 15px;
      width: 100%;

      &-info {
        &--sub {
          color: #929BA9;
          font-size: 14px;
        }

        &--title {
          font-size: 16px;
          margin: 10px 0 0;
        }
      }

      ion-icon {
        color: $color-1;
        font-size: 24px;
      }
    }
  }

  .climate {
    margin-top: 25px;

    ion-button {
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 4px 8px rgba(#000, .2);
      height: auto;
      margin: 0;

      &::part(native) {
        padding: 0;
      }

      .content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        width: 100%;

        div {
          align-items: center;
          color: $font-color;
          display: flex;
          font-size: 16px;
          font-weight: 500;

          ion-icon {
            background-color: rgba(#FFC700, .08);
            color: #FFC700;
            height: 44px;
            margin-right: 15px;
            padding: 5px;
            width: 44px;
          }
        }

        >ion-icon {
          color: $color-1;
          font-size: 28px;
          margin-left: 15px;
        }
      }
    }
  }
}

.climate-rainy {
  margin-top: 25px;

  ion-button {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(#000, .2);
    height: auto;
    margin: 0;

    &::part(native) {
      padding: 0;
    }

    .content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 15px;
      width: 100%;

      div {
        align-items: center;
        color: $font-color;
        display: flex;
        font-size: 16px;
        font-weight: 500;

        ion-icon {
          background-color: rgba(#027CD4, .08);
          color: #027CD4;
          height: 44px;
          margin-right: 15px;
          padding: 5px;
          width: 44px;
        }
      }

      >ion-icon {
        color: $color-1;
        font-size: 28px;
        margin-left: 15px;
      }
    }
  }
}

@include media-down(md) {
  .inicial {
    .activity {
      align-items: flex-start;
      flex-direction: column;
      .title {
        margin-bottom: 10px;
      }
    }
    .actions {
      .title {
        flex-direction: column;

        h2 {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@include media-only(xs) {
  .inicial {
    .activity {
      .title {
        font-size: 18px;
      }
    }
  }
}

//--Programas----------------------------//

.program {
  align-items: center;
  border: 1px solid #F1F0F2;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 15px;
  width: 100%;

  &-info {
    &--title {
      font-size: 20px;
      margin: 0;
    }

    &--sub {
      color: $color-1;
      font-size: 14px;
      font-weight: normal;
      margin: 5px 0 0;

      span {
        color: #929BA9;
      }
    }
  }

  &-icon {
    color: $color-1;
    display: flex;
    font-size: 48px;
  }
}

//--Notícias-----------------------------//

.news {
  align-items: center;
  border: 1px solid #F1F0F2;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 15px;
  width: 100%;

  &-info {
    &--date {
      color: $color-1;
      font-size: 14px;
    }

    &--title {
      font-size: 16px;
      margin: 0 0 8px;
    }

    &--desc {
      color: #637281;
      font-size: 14px;
      line-height: 1.5;

      p {
        margin: 0;
      }
    }
  }

  &-img {
    border-radius: 8px;
    height: 80px;
    margin-left: 15px;
    min-width: 80px;
    overflow: hidden;
    width: 80px;
  }
}

//--Error Message------------------------//

.error-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
  height: 100vh;
  justify-content: center;
  margin-bottom: -140px;
  text-align: center;

  ion-icon {
    color: $color-1;
    font-size: 42px;
    margin-bottom: 5px;
  }

  h2 {
    margin: 0;
  }
}

.fullscreen {
  --width: 60% !important;
  --height: 90% !important;
}

//--Select option size properly------------------------//

.alert-radio-button {
  .alert-radio-icon {
    border-color: $color-1;
  }
  &[aria-checked="true"] {
    .alert-radio-icon {
      border-color: $color-1 !important;
    }
    .alert-radio-inner {
      background-color: $color-1;
    }
  }
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

//--New Passwords style------------------------//
.show-option, .hide-option {
  color: rgb(155, 155, 155);
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

// Custom ionic-selectable
.custom-ionic-selectable{
    .ionic-selectable-cover {
        background: none !important;
        background-color: transparent !important;
    }
}

.ionic-selectable-modal{
    --ion-color-primary: #{$color-1};
    height: 75vh;
    min-height: 75vh;
    margin-bottom: -20px;
    ion-item{
        ion-icon{
            color: $color-1;
        }
        ion-label{
            white-space: normal !important;
            font-size: 16px !important;
        }
    }
    ion-footer{
        display: flex;
        flex-direction: column;
        padding: 16px;
    }
}

ion-modal{
    &:has(ionic-selectable-modal){
        &::part(content){
            align-self: flex-end;
            border-radius: 16px 16px 0 0 !important;
        }
    }
}
$bg-body: #F3F3F3;
$color-1: #FA4616;
$color-2: #FF7F02;
$color-3: #FF7F23;
$color-4: #FFA634;
$color-5: #FFDFAE;
$color-6: #EDF1F7;
$color-7: #F3F3F3;

$font-color: #04182C;
$font-color-2: #929BA9;

$font: 'Roboto';

:root {
    --ion-toolbar-background: #fff;
    --border-width: 0;
    --bullet-background: #{$color-6};
    --bullet-background-active: #{$color-1};
}
//--Transition------------------------------//

@mixin transition($type, $val, $timing) {
    -webkit-transition: $type $val+s $timing;
    -moz-transition: $type $val+s $timing;
    -ms-transition: $type $val+s $timing;
    -o-transition: $type $val+s $timing;
    transition: $type $val + s $timing;
}

//--Flex------------------------------------//

@mixin display-flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin align-items($val) {
    -webkit-align-items: $val;  
    align-items: $val;
}

@mixin justify-content($val) {
    -webkit-justify-content: $val;
    justify-content: $val;
}

@mixin flex-flow($val) {
    flex-flow: $val;
    -webkit-flex-flow: $val;
}

@mixin flex-direction($val) {
    flex-direction: $val;
    -webkit-flex-direction: $val;
    -ms-flex-direction: $val;
}

//--Prefix----------------------------------//

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
}

//--Placeholder-----------------------------//

@mixin placeholder($val){
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: $val;
        opacity: 1;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: $val;
        opacity: 1;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder{
        color: $val;
        opacity: 1;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder{
        color: $val;
        opacity: 1;
    }
}

//--Selector--------------------------------//

@mixin selectionColor($val){
    ::selection {
        background: $val;
    }
    ::-moz-selection {
        background: $val;
    }
}

//--Filter Gradient-------------------------//

@mixin filter-gradient($colors, $direction:left) {
    // Default values: gradient na horizontal da esquerda para direita
    $direction: if($direction == horizontal, left, $direction);
    $wkit-direction1: left top;
    $wkit-direction2: right top;
    $linear-direction: to right;
    $radial-direction: null;
    $gradient-type: 1;

    $type: if($direction == radial, radial, linear);

    @if ($direction == vertical) {
        $direction: top;
        $wkit-direction2: left bottom;
        $linear-direction: to bottom;
        $gradient-type: 0;
    }
    @if ($direction == d-bottom) {
        $direction: -45deg;
        $wkit-direction2: right bottom;
        $linear-direction: 135deg;
    }
    @if ($direction == d-top) {
        $direction: 45deg;
        $wkit-direction1: left bottom;
        $linear-direction: 45deg;
    }
    @if ($direction == radial) {
        $direction: center;
        $radial-direction: ellipse farthest-corner;
        $wkit-direction1: center;
        $wkit-direction2: center center, 0px, center center, 100%;
    }

    $start: quote(nth($colors, 1));
    $start: unquote(str-slice($start, 0, str-index($start, ' ') - 1));
    
    $stop: quote(nth($colors, -1));
    $stop: unquote(str-slice($stop, 0, str-index($stop, ' ') - 1));
    
    $gradient: '';
    @each $color in $colors {
        $string: quote($color);
        $cut-index: str-index($string, ' ');
        
        $cor: unquote(str-slice($string, 0, $cut-index - 1));
        $percent: unquote(str-slice($string, $cut-index + 1));

        $gradient: $gradient + ', color-stop(' + $percent + ', ' + $cor + ')';
    }

    // Backgrounds
    background: $start;
    background: #{$type}-gradient(if($type == linear, $linear-direction, ellipse farthest-corner), $colors);
    background: -ms-#{$type}-gradient($direction, if($type == radial, unquote($radial-direction + ', '), null) $colors);
    background: -webkit-gradient($wkit-direction1, $wkit-direction2 unquote($gradient));
    background: -o-#{$type}-gradient($direction, $colors);
    background: -moz-#{$type}-gradient($direction, if($type == radial, unquote($radial-direction + ', '), null) $colors);
    background: -webkit-#{$type}-gradient($direction, if($type == radial, unquote($radial-direction + ', '), null) $colors);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start}', endColorstr='#{$stop}', GradientType=#{$gradient-type});
}

//--Background------------------------------//

@mixin background($size: cover, $x: center, $y: center, $repeat: no-repeat, $color: transparent) {
    background: {
        size: $size;
        position: $x $y;
        repeat: $repeat;
        color: $color;
    }
}

//--Padding - Margin------------------------//

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (
  "": "all",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@each $space in $spaceamounts {
    @each $prefix, $value in $sides {
        $property: if($prefix == '', '', -#{$value});
        .m-#{$prefix}-#{$space} {
            margin#{$property}: #{$space}px;
        }
        .p-#{$prefix}-#{$space} {
            padding#{$property}: #{$space}px;
        }
    }
}